import React from 'react';
import { Row, Col, ListGroup } from 'reactstrap';

import { MainMenuOption } from '../../Components/MainMenuOption';

const SignedOut = () => (
  <>
    <Row>
      <Col sm={{ size: 8 }} className="mx-auto text-center mb-4">
        <h1>
          Office of Employee Services <small>DCPS</small>
        </h1>
        <p className="lead">
          Forms and help from the Office of Employee Services in District of Columbia Public Schools.
          If you are a current staff member, <a href="https://octo.quickbase.com/db/bh9v8fxr6?a=dbpage&pageID=97">Sign In</a> to
          access additional resources/tools.
        </p>
      </Col>
    </Row>
    <Row>
      <Col md={{ size: 10, offset: 1 }}>
        <ListGroup>
          <MainMenuOption
            icon="fingerprint"
            title="Fingerprint Clearance Form"
            description="Fingerprint clearance form for new/continuing employees, coaches, and volunteers."
            link="https://octo.quickbase.com/db/bg37a864v"
          />
          <MainMenuOption
            icon="school"
            title="Afterschool/Summer School Application"
            description="Apply for or review afterschool or summer school roles."
            link="/afterschool"
          />
          <MainMenuOption
            icon="search"
            title="Employment Verification"
            description="Request a document that verifies your employment at DCPS."
            link="https://octo.quickbase.com/db/bfwh7vt6f"
          />
          <MainMenuOption
            icon="file"
            title="Official Personnel File Request"
            description="Request a copy of your complete Official Personnel File (OPF) or a partial file(I.e. I9, Form SF-50, Transcripts/Certifications etc.)"
            link="https://octo.quickbase.com/nav/app/bh2rjg296/action/appoverview"
          />
        </ListGroup>
      </Col>
    </Row>
  </>
);

export { SignedOut };
