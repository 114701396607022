import React from 'react';
import { Row, Col, ListGroup } from 'reactstrap';

import { MainMenuOption } from '../../Components/MainMenuOption';

const SignedIn = props => (
  <>
    <Row>
      <Col sm={{ size: 8 }} className="mx-auto text-center mb-4">
        <h1>DCPS Office of Employee Services</h1>
        <p className="lead">Forms and resources for current employees.</p>
      </Col>
    </Row>
    <Row>
      <Col md={{ size: 10, offset: 1 }}>
        <ListGroup>
          <MainMenuOption
            icon="fingerprint"
            title="Fingerprint Clearance Form"
            description="Fingerprint clearance form for new/continuing employees, coaches, and volunteers."
            link="https://octo.quickbase.com/db/bg37a864v"
          />
          <MainMenuOption
            icon="school"
            title="Afterschool/Summer School Application"
            description="Apply for or review afterschool or summer school roles."
            link="/afterschool"
          />
          <MainMenuOption
            icon="search"
            title="Employment Verification"
            description="Request a document that verifies your employment at DCPS."
            //link="/forms/employment-verification"
            link="https://octo.quickbase.com/db/bfwh7vt6f"
          />
          <MainMenuOption
            icon="file"
            title="Official Personnel File Request"
            description="Request a copy of your complete Official Personnel File (OPF) or a partial file(I.e. I9, Form SF-50, Transcripts/Certifications etc.)"
            link="https://octo.quickbase.com/nav/app/bh2rjg296/action/appoverview"
          />
          <MainMenuOption
            icon="sign-out-alt"
            title="Resign from DCPS"
            description="Indicate your intent to resign from DC Public Schools."
            link="/resign"
          />
          <MainMenuOption
            icon="gift"
            title="Retire with DCPS"
            description="Begin the retirement process with DC Public Schools."
            link="/retire"
          />
          <MainMenuOption
            icon="user-md"
            title="Request a Leave of Absence"
            description="Submit a request for FMLA, PFL or Extended Leave."
            link="leave-of-absence"
          />
          <MainMenuOption
            icon="flag"
            title="Mandated Reporter Training"
            description="Review instructions for completing mandated reporter training and sharing proof of completion with DCPS."
            link="/forms/mandated-reporter"
          />
          <MainMenuOption
            icon="chess"
            title="Extra Duty Payments"
            description="Register extra duty activities and request payment."
            link="https://octo.quickbase.com/db/bh5bvv8u5"
          />
          <MainMenuOption
            icon="graduation-cap"
            title="Education Level Promotions"
            description="Submit documents indicating higher educational achievements."
            link="https://octo.quickbase.com/db/bh2rjg296"
          />
          <MainMenuOption
            icon="money-bill"
            title="Tuition Reimbursement Request"
            description="Request reimbursement for tuition."
            link="https://octo.quickbase.com/db/bh2rjg296"
          />
          <MainMenuOption
            icon="subway"
            title="Register Metro Pass"
            description="Register a Metro Pass for Monthly Allowance (AFSCME members only)"
            //link="forms/metropass"
            link="https://octo.quickbase.com/db/bh2rjg296"
          />
          <MainMenuOption
            icon="medkit"
            title="Update Benefits Enrollment"
            description="Submit a request for updated benefits enrollment after a life event"
            link="https://octo.quickbase.com/db/bh2rjg296"
          />
          <MainMenuOption
            icon="calendar-alt"
            title="Reasonable Assurance of Employment"
            description="Confirm reasonable assurance of employment after summer break (AFSCME members)"
            link="https://octo.quickbase.com/db/bh57wbkp6"
          />
          <MainMenuOption
            icon="wheelchair"
            title="Request an ADA Accomodation"
            description="Request an workplace accomodation guaranteed by the Americans with Disabilities Act (ADA)."
            link="https://octo.quickbase.com/db/bexkv8n7w"
          />
        </ListGroup>
      </Col>
    </Row>
  </>
);

export { SignedIn };
