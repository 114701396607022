import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExternalLinkAlt,
  faFingerprint,
  faSchool,
  faSearch,
  faSignOutAlt,
  faGift,
  faUserMd,
  faIdCard,
  faChess,
  faGraduationCap,
  faMoneyBill,
  faSubway,
  faMedkit,
  faGavel,
  faCalendarAlt,
  faWheelchair,
  faComments,
  faAddressBook,
  faUsers,
  faFutbol,
  faStopwatch,
  faTable,
  faFlag,
  faInfoCircle,
  faFileExcel,
  faCaretUp,
  faCaretDown,
  faChalkboardTeacher,
  faCalculator,
  faFile
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faExternalLinkAlt,
  faFingerprint,
  faSchool,
  faSearch,
  faSignOutAlt,
  faGift,
  faUserMd,
  faIdCard,
  faChess,
  faGraduationCap,
  faMoneyBill,
  faSubway,
  faMedkit,
  faGavel,
  faCalendarAlt,
  faWheelchair,
  faComments,
  faAddressBook,
  faUsers,
  faFutbol,
  faStopwatch,
  faTable,
  faFlag,
  faInfoCircle,
  faFileExcel,
  faCaretUp,
  faCaretDown,
  faChalkboardTeacher,
  faCalculator,
  faFile
);

export { library };
